import React from "react";
import { connect } from "react-redux";
import NavBar from "../../Components/NavBar/NavBar";
import Header from "../../Components/Header/Header";
import { ArrowIcon, FallbackClipArt } from "../../Assets/assets";
import { isValidArray } from "../../Services/validators";
import { dateAndTimeConverter } from "../../Utils/constants";
import { setSelectedProcedure } from "../../Redux/procedures/action";

function Procedures(props) {
  return (
    <div className="inherit-parent-height ">
      <Header title="Procedures " />
      <div className=" inherit-parent-width remaining-body-height_-128px flex-justify-content-center ">
        <div className="inherit-parent-height inherit-parent-width max-width-767px overflow-x-scroll hide-scrollbar">
          {(props.locations.loading ||
            props.procedures.loading ||
            isValidArray(props.profile.data?.linkedProcedures)) && (
            <div className="display-flex padding-top-large padding-left-large padding-right-large flex-direction-column">
              <div className="font-family-RHD-medium ">Upcoming Procedures</div>
              <div className=" font-size-small padding-bottom-default">
                View the upcoming procedures
              </div>
            </div>
          )}
          {!props.locations.loading &&
            !props.procedures.loading &&
            isValidArray(props.profile.data?.linkedProcedures) && (
              <div
                className="inherit-parent-width padding-left-large padding-right-large"
                data-cy="procedures-list"
              >
                {props.profile.data?.linkedProcedures
                  ?.sort(
                    (a, b) =>
                      parseInt(props.locations.data[a.locationId]?.PCTNo) -
                      parseInt(props.locations.data[b.locationId]?.PCTNo)
                  )
                  ?.map((data, index) => (
                    <div key={index}>
                      <Card
                        procedures={props.procedures.data}
                        locations={props.locations.data}
                        data={data}
                        onClick={() => {
                          props.setSelectedProcedure(data);
                          props.navigate(`/procedures/${data.procedureId}`);
                        }}
                      />
                    </div>
                  ))}
              </div>
            )}
          {!props.locations.loading &&
            !props.procedures.loading &&
            !isValidArray(props.profile.data?.linkedProcedures) && (
              <NoProceduresFallback />
            )}
          {(props.locations.loading || props.procedures.loading) && (
            <div className="display-flex flex-direction-column inherit-parent-width">
              <Suspense />
            </div>
          )}
        </div>
      </div>
      <div className="position-absolute bottom-0 inherit-parent-width">
        <NavBar navigate={props.navigate} />
      </div>
    </div>
  );
}

const Card = (props) => {
  return (
    <div
      className=" display-flex flex-justify-content-space-between flex-align-items-center font-size-small padding-top-large"
      data-cy={`${props.data.procedureId}-${props.data.locationId}`}
      key={props.data.documentId}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.procedures?.[props.data.procedureId]?.title &&
      props.locations?.[props.data.locationId]?.location ? (
        <>
          <div>
            <div className="font-size-medium font-family-RHD-medium">
              {props.procedures?.[props.data.procedureId]?.title}
            </div>
            <div className=" padding-top-small">{`${props.locations?.[
              props.data.locationId
            ]?.PCTNo?.toString().padStart(2, "0")}
                        - ${
                          props.locations?.[props.data.locationId]?.location
                        }`}</div>
          </div>
          <div className=" display-flex">
            <div className=" padding-right-default">
              {dateAndTimeConverter(props.data.timeStamp, "cardDate")}
            </div>
            <ArrowIcon />
          </div>
        </>
      ) : (
        <>
          <div className=" padding-default inherit-parent-width">
            <div className="shimmer padding-larger border-radius-default inherit-parent-width" />
          </div>
        </>
      )}
    </div>
  );
};

const NoProceduresFallback = () => {
  return (
    <div
      className=" inherit-parent-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
      data-cy="no-procedures-fallback"
    >
      <FallbackClipArt />
      <div className=" padding-top-larger font-family-RHD-medium">
        No procedures yet
      </div>
      <div className=" padding-top-medium text-align-center font-size-medium">
        Please check after sometime.
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  procedures: state.procedures,
  locations: state.locations,
});

const mapDispatchToProps = function () {
  return {
    setSelectedProcedure: (currentProcedure) =>
      setSelectedProcedure(currentProcedure),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Procedures);

const Suspense = () => {
  return [...Array(10)].map((_data, index) => (
    <div className=" padding-default" key={index}>
      <div className="shimmer padding-larger border-radius-default  inherit-parent-width" />
    </div>
  ));
};
